import React from 'react'
import BookAppointmentForm from './BookAppointmentForm'
import GetSeoData from "../API/GetSeoData";

const AppointmentPage = () => {
    return (
        <div>
            <GetSeoData id={null} type="book slot" />

            <div className="page-title-area contact-banner">
                <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                    <div className="page-title-content">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Book An Appointment</li>
                        </ul>
                    </div>
                </div>
            </div>
            <BookAppointmentForm closeModal="" type="page" />
        </div>
    )
}

export default AppointmentPage