import React from 'react'
import { Modal } from 'react-bootstrap'
import BookAppointmentForm from './BookAppointmentForm'

const appointmentform = ({ serviceName, showForm, closeModal }) => {
  return (
    <div>
      <Modal
        show={showForm}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="xl"
        className="appointment-form-modal p-0"
      >
        <Modal.Header closeButton className="pb-0"></Modal.Header>
        <Modal.Body className="p-lg-0">
          <BookAppointmentForm closeModal={closeModal} type="modal" />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default appointmentform