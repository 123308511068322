import "./HomeMediaService.css";

import { Helmet } from "react-helmet";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Skeleton } from "antd";
import imageone from "../img/gallery/3.jpg";
import imagetwo from "../img/gallery/33.jpg";
import imagethree from "../img/gallery/30.jpg";
import imagefour from "../img/gallery/43.jpeg";
// import imagefive from "../img/gallery/26.jpg";
import imagesix from "../img/gallery/17.jpg";
import imageseven from "../img/gallery/24.jpg";

export default function HomeMediaService() {
  return (
    <>


      <section className="inner-section spacing products-page mt-4 main-product-page">
        <div className="container-fluid pb-3">
          <div className="row">
            {/* <div className="text-center main-heading  col-12 text-center t-head mb-lg-2">
                            <h2 className="heading-two mb-0">
                                <span>Trending</span> Items
                            </h2>
                            <p>Lorem Lorem Lorem Lorem Lorem Lorem Lorem</p>
                        </div> */}

            <div className="text-center col-12 text-center t-head mb-2 main-heading">
              <h2 className="mb-0">Podcast</h2>
              <p>Exclusive Interviews and Media Appearances</p>
            </div>
            <div className="col-12">
              <Swiper
                spaceBetween={30}
                // loop={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper testimonial-swiper pt-2">

                <SwiperSlide>

                  <div className="">
                    <iframe width="338" height="402" src="https://www.youtube.com/embed/NyIduGfkFQ8" title="Manifest Your PR Dreams in 2025! ✨🌍" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                </SwiperSlide>
                <SwiperSlide>

                  <div className="">
                    <iframe width="338" height="402" src="https://www.youtube.com/embed/pTCNu203-vU" title="Why Financial Blockages Happen &amp; How to Overcome Them 💰" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                                    </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="">
                    <iframe
                      width="338"
                      height="402"
                      src="https://www.youtube.com/embed/4BsTeZhn_ks"
                      title="मेरे घर में यमराज आए थे 😲🙏  #TVTPodcast #Podcast #shorts"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <iframe
                      width="338"
                      height="402"
                      src="https://www.youtube.com/embed/cZDvsnNYil8"
                      title="शमशान की जमीन पर घर बनाया है 😱😨  #TVTPodcast #Podcast #shorts"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <iframe
                      width="338"
                      height="402"
                      src="https://www.youtube.com/embed/d-MjFCOfRqM"
                      title="बिना शरीर भगवान भी क्या करते❓❓#tvtpodcast #truevaluetalks #tvt #shorts"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="">
                    <iframe
                      width="338"
                      height="402"
                      src="https://www.youtube.com/embed/sbhhBQ-AnTQ"
                      title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </SwiperSlide> */}
                <SwiperSlide>
                  <div className="">
                    <iframe
                      width="338"
                      height="402"
                      src="https://www.youtube.com/embed/sbhhBQ-AnTQ"
                      title="क्या शनि देंगे आपका साथ या😱😱 #tvtpodcast #truevaluetalks #tvt #shorts"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div class="read-more col-12 text-center mt-3">
              <a href="/podcast"> View All</a>
            </div>
          </div>

          {/* <div className="btn-box text-center">
            <a href="/" className="button button-2">
              View All
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
}
