import React from "react";
import "./nav.css";
import logo from "../img/newlogo.png";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdCart, IoMdMail } from "react-icons/io";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaUser,
  FaYoutubeSquare,
} from "react-icons/fa";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";

import { Dropdown } from "antd";
import { NavDropdown } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import PostApiCall from "../API/POSTAPI";
import { store } from "../Helper/Store";
import Notiflix from "notiflix";
import LoginModal from "../Login/LoginModal";
import ChangePassword from "../Login/ChangePassword";
import LogoutModal from "../Login/LogoutModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoCall } from "react-icons/io5";
import GetApiCall from "../API/GETAPI";

export default function NavMain() {
  const history = useHistory();
  const [scroll, setScroll] = useState(false);
  const {
    cart,
    setcart,
    setcartamount,
    clientData,
    loggedIn,
    setLoggedIn,
    siteHeader,
    setCartItems,
    setClientData,
    setSiteHeader,
    homeCategory,
    setHomeCategory,
    loginModalStatus,
    setLoginModalStatus,
    modalSide, setModalSide,
  } = useContext(store);

  // const [loginModalStatus, setLoginModalStatus] = useState(false);
  // const [modalSide, setModalSide] = useState("");
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [canLogout, setCanLogout] = useState(false);

  useEffect(() => {
    getCustomerDetails();
    GetSiteSettingsV1();
    GetProductVertical();
  }, [loggedIn]);
  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
    }
  };
  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          const activeCategory = obj1.data.filter(
            (item) => item.fld_status === "Active"
          );
          setHomeCategory(activeCategory);
        }
      })
    );
  };
  const GetSiteSettingsV1 = () => {
    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          // setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          setClientData(obj1.data);
          // setSiteHeader(JSON.parse(obj1.data[0]?.site_header)?.menu[0]?.subMenu)
          // setStoreStatus(obj1.data[0].storeStatus);
        }
      })
    );
  };
  // login specific functions
  const handleToggleModal = () => {
    setLoginModalStatus(false);
    setModalSide("Log")
  };
  const handleChangeModalSide = (e) => {
    console.log(e)
    setModalSide(e);
  };
  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleProfileClick = () => {
    history.push("/customer-profile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  // change password specific functions
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  const handleOpenCart = () => {
    if (loggedIn) {
      // console.log(loggedIn)
      // return <Redirect to="/cart" />
      history.push("/cart");
    } else {
      setModalSide("Log");
      setLoginModalStatus(true);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setModalSide("Log");
            setLoginModalStatus(true);
            //   clearItemDetails();
          }}
        >
          Login
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            setModalSide("Reg");
            setLoginModalStatus(true);
            //   clearItemDetails();
          }}
        >
          Sign Up
        </span>
      ),
    },
  ];

  const loggedInitems = [
    {
      key: "1",
      label: <span onClick={handleProfileClick}>Profile</span>,
    },
    {
      key: "2",
      label: <span onClick={changePssword}>Change Password</span>,
    },
    {
      key: "3",
      label: <span onClick={() => setCanLogout(true)}>Log Out</span>,
    },
  ];
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    getCartdetails();
  }, []);
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      setCartItems([]);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };

  const getCartdetails = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        // console.log(res1);
        if (res1.data) {
          setCartItems(res1.data);
          Notiflix.Loading.remove();
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };
  return (
    <>
      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      <div
        className={
          scroll ? "nav-container fixed-top spacing" : "nav-container spacing "
        }
      >
        <Navbar key={"lg"} expand={"lg"}>
          <Container fluid>
            <div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-lg`}
                className="border-0 ps-0"
              >
                <HiMiniBars3CenterLeft />
              </Navbar.Toggle>
              <Navbar.Brand href="/">
                {" "}
                <img src={logo} className="img-fluid" alt="Blessings Astrology Logo"></img>
              </Navbar.Brand>
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="start"
            >
              <Offcanvas.Header className="logo-image" closeButton>
                <img src={logo} alt="Blessings Astrology Logo" className="img-fluid"></img>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 ps-lg-4">
                  {/* <Nav.Link href="/" className="ps-lg-0 nav-link">
                  Home
                </Nav.Link> */}
                  <Nav.Link href="/about-us">About Us</Nav.Link>
                  <Nav.Link href="/services">Our Services</Nav.Link>
                  <Nav.Link href="/horoscope-prediction">Horoscope </Nav.Link>
                  {/* <Nav.Link href="/our-products">Our Products</Nav.Link> */}
                  <NavDropdown
                    className=""
                    data-toggle="dropdown"
                    title="Shop Online"
                    id="offcanvasNavbarDropdown-expand-sm"
                    renderMenuOnMount={true}
                  >
                    {/* <NavDropdown.Item>Books</NavDropdown.Item> */}
                    {homeCategory.map((items, index) => (
                      <NavDropdown.Item
                        className="allshopnow"
                        href={`/ct/${items.fld_route}`}
                        // href={`/ct/${items.selected_cat_name?.replace(
                        //   /\s/g,
                        //   "-"
                        // )}/${items.fld_verticleid}/${items.selected_cat_id}`}
                        key={items.fld_verticleid}
                      >
                        {items.fld_name}
                      </NavDropdown.Item>
                    ))}
                    {/* <NavDropdown.Item href="/ct/products/1">
                      Products
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/ct/products/1">
                      Books
                    </NavDropdown.Item>

                    <NavDropdown.Item href="/ct/products/1">
                      Healing Jewellery
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/ct/products/1">
                      Remedial Items
                    </NavDropdown.Item> */}
                  </NavDropdown>

                  <Nav.Link href="/blogs">Blogs</Nav.Link>
                  {/* <Nav.Link href="/gallery">Gallery</Nav.Link>
                                    <Nav.Link href="/media">Media</Nav.Link> */}
                  {/* <Nav.Link href="/our-books">Our Books</Nav.Link> */}

                  {/* <Nav.Link href="/contact-us">Book An Appointment</Nav.Link> */}
                </Nav>
                <Nav.Link href="/contact-us" className="nav-link d-lg-none">
                  Contact Us
                </Nav.Link>
                <div className="d-flex contact-links justify-content-start my-2 d-lg-none">
                  <IoCall className="linkk" />
                  {/* <a href={`tel:${clientData[0]?.mobile}`}>
                    {clientData[0]?.mobile}
                  </a> */}
                  <a href="tel:+919821155251">+919821155251</a>
                </div>
                <div className="d-flex contact-links justify-content-start mb-3 d-lg-none">
                  <IoMdMail className="linkk" />
                  <a href={`mailto:${clientData[0]?.email}`}>
                    {clientData[0]?.email}
                  </a>
                </div>
                <h6 className="d-lg-none d-block">Follow us on</h6>
                <ul className="d-flex contact-links my-2 ps-0 justify-content-start linkk d-lg-none d-block">
                  {/* <SocialIcons
                                    link={clientData[0]?.facebook}
                                    link="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                                    target="_blank"
                                    logo={<FaFacebookF />}
                                /> */}
                  <a
                    className="ms-lg-2"
                    href="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                    target={"_blank"}
                  >
                    <FaFacebookSquare />
                  </a>
                  {/* <SocialIcons
                                    link={clientData[0]?.instagram}
                                    target="_blank"
                                    logo={<FaInstagramSquare />}
                                />
                                <SocialIcons
                                    link={clientData[0]?.twitter}
                                    target="_blank"
                                    logo={<FaYoutubeSquare />}
                                />
                                <SocialIcons
                                    link={clientData[0]?.linkedin}
                                    target="_blank"
                                    logo={<FaLinkedin />}
                                />

                                <SocialIcons
                                    link={clientData[0]?.youtube}
                                    target="_blank"
                                    logo={<FaYoutube />}
                                /> */}
                  <a
                    className="ms-2"
                    href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
                    target={"_blank"}
                  >
                    <FaInstagramSquare />
                  </a>
                  <a
                    className="ms-2"
                    href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
                    target={"_blank"}
                  >
                    <FaYoutubeSquare />
                  </a>
                  <a
                    className="ms-2"
                    href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
                    target={"_blank"}
                  >
                    <FaLinkedin />
                  </a>
                </ul>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

            <div className="icon-box d-flex">
              {loggedIn === true && (
                <Dropdown
                  menu={{ items: loggedInitems }}
                  placement="bottomRight"
                >
                  <a
                    href="javascript:void(0)"
                    className="user-dropdown user-icon"
                  >
                    <FaUser />
                  </a>
                </Dropdown>
              )}
              {loggedIn === false && (
                <Dropdown menu={{ items }} placement="bottomRight">
                  <a
                    href="javascript:void(0)"
                    className="user-dropdown user-icon"
                  >
                    <FaUser />
                  </a>
                </Dropdown>
              )}

              <a href="/cart" className="cart-icon">
                <IoMdCart className="cartt-icon" />
                <span className="text-white">{cart} Items</span>
              </a>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
