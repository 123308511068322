import { Collapse, Skeleton } from "antd";
import axios from "axios";
import parse from "html-react-parser";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { BiSolidOffer } from "react-icons/bi";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { CiViewList } from "react-icons/ci";
import { FaHome } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiSuitcase } from "react-icons/gi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp, IoMdCart } from "react-icons/io";
import { IoCheckmarkDone, IoLocationSharp } from "react-icons/io5";
import { MdEditLocationAlt } from "react-icons/md";
import { RiCoupon2Fill, RiCoupon2Line, RiEBike2Line } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import AddressBookForm from "../Address/AddressBookForm";
import AddToCartQty from "../AddToCart/AddToCartQty";
import { store } from "../Helper/Store";
import GetApiCall from "../API/GETAPI";
import PostApiCall from "../API/POSTAPI";
import "./Cart.css";
import NoItem from "../img/no-item-in-cart.png";
import GetSeoData from "../API/GetSeoData";
export default function Cart() {
  const [addressData, setAddressData] = useState([]);
  const [check, setCheck] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [COD, setCOD] = useState("No");
  const [cartID, setCartID] = useState(null);
  const [couponCard, setCouponCard] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const [couponCode, setCouponCode] = useState(null);
  const [offerErrorCode, setOfferErrorCode] = useState("");
  const [offerErrorMessage, setOfferErrorMessage] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [showCouponMoreInformation, setShowCouponMoreInformation] =
    useState("");
  const [enteredCouponCode, setEnteredCouponCode] = useState(null);
  const [couponErrorCodeTextBox, setCouponErrorCodeTextBox] = useState(null);
  const [offerErrorMessageTextBox, setOfferErrorMessageTextBox] = useState("");
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [stickyTop, setStickyTop] = useState(false);
  const [paymentTrxNum, setPaymentTrxNum] = useState("");

  const history = useHistory();

  const {
    logo,
    cart,
    setcart,
    cartamount,
    setcartamount,
    clientData,
    cartData,
    setCartData,
    storeStatus,
  } = useContext(store);
  const handleCloseCanvas = () => {
    setShowCanvas(false);
  };
  const handleToggle = () => {
    setCouponCard(false);
    setCouponErrorCodeTextBox(null);
    setOfferErrorMessage("");
    setEnteredCouponCode(null);
  };
  if (cartData.length) {
    var cartItemsDetail = cartData.map((item) => ({
      name: item.fld_variantname,
      price: item.fld_selling_price,
      quantity: item.fld_quantity,
    }));
  }

  useEffect(() => {
    Notiflix.Loading.circle();
    getCartdetails();
    GetOfferList();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const GetOfferList = () => {
    GetApiCall.getRequest("GetOfferList").then((resultdes) =>
      resultdes.json().then((obj) => {
        setOfferData(
          // eslint-disable-next-line eqeqeq
          obj.data.filter((value) => value.fld_showonwebsite == "Active")
        );
      })
    );
  };
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (showCanvas != true) {
      getCustomerAddress();
    }
  }, [showCanvas]);

  const getCustomerAddress = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    // eslint-disable-next-line eqeqeq
    if (login != null || login != undefined) {
      PostApiCall.postRequest(
        {
          whereClause: `where fld_customerid=${logindetails[0].fld_customerid}`,
        },
        "GetCustomerAddress"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setAddressData(obj.data);
            Notiflix.Loading.remove();
          }
        })
      );
    }
  };

  /////////////////////////////////////// handlers //////////////////////////////////////////////////////////////////
  const getCartdetails = () => {
    Notiflix.Loading.circle();
    getCartwebsite();
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      toggleVisible();
    });
  }, []);

  const getCartwebsite = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    ).then((result) => {
      result.json().then((obj) => {
        // console.log(obj.data)
        if (result.status === 200 || result.status === 201) {
          setcart(obj?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(
          //   obj.data.length == 0 ? 0 : obj.data[0].fld_cart_amount_preview
          // );
          GetOfferList();
          if (obj.data.length > 0) {
            const filteredCoupons = offerData.filter(
              (coupon) => coupon.fld_offerid !== obj.data[0]?.fld_coupon_id
            );

            // Use find instead of filter to get a single coupon
            const selectedCoupon = offerData.find(
              (coupon) => coupon.fld_offerid === obj.data[0]?.fld_coupon_id
            );
            // Update state with the new coupons array
            setOfferData(
              (selectedCoupon ? [selectedCoupon] : []).concat(
                filteredCoupons.map((coupon, index) => ({
                  ...coupon,
                  selectedCoupon: selectedCoupon && index === 0,
                }))
              )
            );
            setCartData(obj.data);
            setCartID(obj.data[0].fld_cart_id);
            // setCouponId(obj.data[0].fld_offerid);
            setCouponCode(obj.data[0].fld_code);
            setcartamount(obj.data[0].fld_cart_amount);
            setPaymentTrxNum(obj.data[0].fld_payment_trx_num);
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
          }
        }
      });
    });
  };

  const removeCoupon = (cartid, couponid) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        orderid: cartid,
        couponid: couponid,
        stateid: localStorage.getItem("ChangedPincode"),
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        status: "remove",
      },
      "ApplyOffer"
    ).then((result) =>
      result.json().then((obj3) => {
        if (result.status === 200 || result.status === 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Coupon removed successfully");
          setOfferErrorCode("");
          setOfferErrorMessage("");
          getCartdetails();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj3.data);
        }
      })
    );
  };

  const handleConfirmAddress = () => {
    if (shippingAddress == "" || shippingAddress == null) {
      Notiflix.Notify.failure("Please select your shipping address");
      return;
    }
    if (billingAddress == "" || billingAddress == null) {
      Notiflix.Notify.failure("Please select your billing address");
      return;
    }
    handlePlaceOrder();
  };

  const handlePlaceOrder = () => {
    Notiflix.Loading.dots("Please wait....");
    PostApiCall.postRequest(
      {
        cartid: cartID,
        billingaddress: billingAddress,
        shippingaddress: shippingAddress,
        // deliverydate: selectedDate,
        // deliverytime: selectedTimeSlot,
      },
      "UpdateCartAddAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          // Notiflix.Notify.success("Order placed successfully")
          handlePayment(parseFloat(
            cartamount
          ).toFixed(0));
          // handlePayment(1)

          // window.location.href = "/order_placed";
        }
      })
    );
  };

  // pay to razorpay
  const handlePayment = async (amount) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    // e.preventDefault();
    try {
      const response = await axios.post(
        // "http://localhost:3035/blessings-api/payment",
        "https://api.bechofy.in/blessings-api/payment",
        { amount }
      );
      const { order } = response.data;
      var options = {
        key: process.env.REACT_PAYMENT_KEY || "rzp_live_PDnM4qUQpUe7rE", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: process.env.REACT_NAME || "Blessings Astrology", //your business name
        description: "Blessings Astrology",
        image: logo,
        order_id: order.id, // Pass the `id` obtained in the response of Step 1
        handler: (response) => {
          const body = {
            ...response,
          };

          fetch(
            // "http://localhost:3035/blessings-api/RazorPayPaymentStatus",
            "https://api.bechofy.in/blessings-api/RazorPayPaymentStatus",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((validateRes) => validateRes.json())
            .then((jsonRes) => {
              axios
                .post(
                  // "http://localhost:3035/blessings-api/RazorPayResponse",
                  "https://api.bechofy.in/blessings-api/RazorPayResponse",
                  {
                    cartid: cartID,
                    customerid:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_customerid,
                    amount: cartamount,
                    productinfo: "",
                    firstname:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_name,
                    email:
                      localStorage.getItem("CustomerData") == null
                        ? null
                        : logindetails[0].fld_email,
                    mihpayid: jsonRes.status.id,
                    requestid: jsonRes.status.order_id,
                    txnamount: jsonRes.status.amount,
                    errorcode: jsonRes.status.error_code,
                    paymentsource: jsonRes.status.method,
                    errormsg: jsonRes.status.error_description,
                    shippingaddress: shippingAddress,
                    billingaddress: billingAddress,
                    status: jsonRes.status.status,
                  }
                )
                .then((obj) => {
                  if (obj.data.message === true) {
                    // Redirect to payment success page
                    handleSendNotification(jsonRes.status.order_id);
                    history.push(`/paymentsuccess/${paymentTrxNum}`);
                    // window.location.href = `/paymentsuccess/${paymentTrxNum}`;
                  } else {
                    // Redirect to payment failure page
                    // window.location.href = `/paymentfail/${paymentTrxNum}`;
                  }
                });
              // window.location.href = "/paymentsuccess"
            })
            .catch((error) => {
              console.error("Error fetching payment status:", error);
            });
        },
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_name, //your customer's name
          email:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_email,
          contact: localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_mobile === null ? null : logindetails[0].fld_mobile,
          // contact: "9000000000", //Provide the customer's phone number for better conversion rates
        },
        notes: {
          cartid: cartID,
          itemsdetaiils: JSON.stringify(cartItemsDetail),
          paymentTrxNum: paymentTrxNum,
        },
        theme: {
          color: "#ad9551",
        },
      };

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        // Razorpay script is loaded, you can now use window.Razorpay
      };
      Notiflix.Loading.remove();
      document.head.appendChild(script);
      const rzp = new window.Razorpay(options);
      // setShowCanvas(true)
      rzp.open();
    } catch (error) {
      console.error(error);
    }
  };
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    var screenWidth = window.innerWidth;

    if (scrolled > 100 && screenWidth > 992) {
      setStickyTop(true);
    } else if (scrolled <= 0) {
      setStickyTop(false);
    }
  };

  const handleSendNotification = (orderid) => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    PostApiCall.postRequest(
      {
        orderNumber: orderid,
        customerName: logindetails[0]?.fld_name,
        amount: 100,
        // cartData[0]?.currency_code + " " + cartData.length != 0 &&
        //   cartData[0]?.fld_cart_amount_no_shipping != null
        //   ? cartData[0]?.fld_cart_amount_no_shipping.toFixed(2)
        //   : 0.0,
        location: "",
        orderTime: "10:00AM",
        title: "Hi, you have a new order",
        body: `${logindetails[0]?.fld_name} placed an order`,
      },
      "SendAndroidNotification"
    ).then((result) =>
      result?.json().then((obj3) => {
        if (result.status === 200 || result.status === 201) {
          // addAddress();
          // updateOrderStatus();
          // getCart();
          Notiflix.Notify.success("Your order has been placed successfully");
          // handlepaymentModalClose();
          // handleClose();
          // navigate('/payment-successful');
        } else {
          Notiflix.Notify.failure("Oops!", "Something went wrong", "OK");
        }
      })
    );
  };

  return (
    <>
      <GetSeoData type="Cart" id={null} />
      <div className="page-title-area cart-page">
        <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
          <div className="page-title-content">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>cart</li>
            </ul>
          </div>
        </div>
      </div>
      {cart > 0 ? (
        <div className="container-fluid px-lg-4 my-4">
          <div className="row">
            <div className="col-lg-7">
              <Collapse
                className="w-100 cartcheckout-accordion"
                accordion
                items={[
                  {
                    key: "1",
                    label: (
                      <>
                        <div className="d-flex align-items-center">
                          <span className="cart-accordian-icons">
                            <IoMdCart />
                          </span>
                          <h4 className="main-heading me-lg-3 mb-0">My Cart</h4>
                          <p className="mb-0  px-2 ms-auto me-lg-3 py-1 mb-0 read-more">
                            <a> Total {cart} items</a>
                          </p>
                        </div>
                      </>
                    ),
                    children: (
                      <div className="checkout-bx pb-0">
                        {cartData?.length > 0 ? (
                          cartData.map((product, index) => (
                            <div className="position-relative" key={index}>
                              <div className="row position-relative mb-4 py-3 pb-md-0 rounded w-100 m-0">
                                <div className="col-4 col-md-2">
                                  <div className="row">
                                    <div className="col-12">
                                      <Link
                                        to={`${process.env.PUBLIC_URL
                                          }/p/${product.fld_route?.replace(
                                            /\s/g,
                                            "-"
                                          ).replace('/', '')}`}>
                                        <img
                                          src={product.VariantImage}
                                          alt={product.fld_itemname}
                                          className="checkout-img mb-lg-3"></img>
                                        <span
                                          className={
                                            product.item_type == null
                                              ? "d-none"
                                              : "veg-nonveg-icon"
                                          }></span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-8 col-md-10">
                                  <div className="row">
                                    <div className="col-md-6 cproduct-name cproduct-card-name">
                                      <span className="d-block">
                                        {product.fld_categoryname}
                                      </span>
                                      <Link
                                        className="fw-bold"
                                        to={`${process.env.PUBLIC_URL
                                          }/p/${product.fld_route?.replace(
                                            /\s/g,
                                            "-"
                                          ).replace('/', '')}`}>
                                        {/* {product.fld_itemname}{"-"} */}
                                        {product.fld_variantname}
                                      </Link>

                                      <div className="c-price">
                                        <span className="d-flex mt-2">
                                          <div className="prod-price me-2 mb-0 pb-0">
                                            ₹{" "}
                                            {parseFloat(
                                              product.fld_website_discount_price *
                                              product.fld_quantity
                                            ).toFixed(0)}
                                          </div>
                                          {product.fld_mrp >
                                            product.fld_website_discount_price && (
                                              <del>
                                                {" "}
                                                ₹{" "}
                                                {parseFloat(
                                                  product.fld_mrp *
                                                  product.fld_quantity
                                                ).toFixed(0)}
                                              </del>
                                            )}
                                        </span>
                                      </div>
                                      <div className=" d-none d-md-block cquantity mt-md-3">
                                        <AddToCartQty
                                          itemid={product.fld_variantid}
                                          quantity={product.fld_quantity}
                                          cartlineid={product.fld_cart_dtl_id}
                                          shippingAddress={shippingAddress}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 d-block d-md-none cquantity">
                                  <div className="quantity">
                                    <AddToCartQty
                                      itemid={product.fld_variantid}
                                      quantity={product.fld_quantity}
                                      cartlineid={product.fld_cart_dtl_id}
                                      shippingAddress={shippingAddress}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                        ) : (
                          <div className="position-relative">
                            <div className="row position-relative mb-4 py-3 pb-md-0 rounded w-100 m-0">
                              <div className="col-4 col-md-2">
                                <div className="row">
                                  <div className="col-12">
                                    <Skeleton.Image className="checkout-img mb-lg-3" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-8 col-md-10">
                                <div className="row">
                                  <div className="col-md-6 cproduct-name cproduct-card-name">
                                    <span className="d-block">
                                      <Skeleton.Input />
                                    </span>
                                    {/* {product.fld_itemname}{"-"} */}
                                    <Skeleton.Input />

                                    <div className="c-price">
                                      <span className="d-flex mt-2">
                                        <div className="prod-price me-2 mb-0 pb-0">
                                          <Skeleton.Input />
                                        </div>
                                      </span>
                                    </div>
                                    <div className=" d-none d-md-block cquantity mt-md-3">
                                      <Skeleton.Button />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 d-block d-md-none cquantity">
                                <div className="quantity">
                                  <Skeleton.Button />
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div className="d-flex align-items-center">
                        <span className="cart-accordian-icons">
                          <IoLocationSharp />
                        </span>
                        <h4 className="main-heading mb-0">Shipping Address</h4>
                      </div>
                    ),
                    children: (
                      <>
                        <div className="col-12 d-flex justify-content-between align-items-center p-2 my-2">
                          <div className="w-100 p-2 d-flex justify-content-between">
                            <h4 className="mb-0">My Address List</h4>
                            <div className="read-more new-address">
                              <a
                                className=" border-0 d-inline-block"
                                onClick={() => {
                                  setShowCanvas(true);
                                }}>
                                Add Address
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* display the existing addresses*/}
                        <div className="col-12">
                          <div className="row m-0 w-100">
                            {addressData.length > 0 ? (
                              <>
                                {addressData.map((data, index) => (
                                  <>
                                    <div className="Col-12" key={index}>
                                      <div className="boxes">
                                        <div className="d-flex">
                                          <div className="p-2 bg-light text-center gap-2 pe-3 d-flex">
                                            <input
                                              type="radio"
                                              checked={
                                                selectedAddress.fld_address_id ===
                                                  data.fld_address_id
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => {
                                                setSelectedAddress(data);
                                                setShippingAddress(
                                                  data.fld_address_id
                                                );
                                                {
                                                  const det =
                                                    localStorage.getItem(
                                                      "CustomerData"
                                                    );
                                                  const details =
                                                    JSON.parse(det);
                                                  PostApiCall.postRequest(
                                                    {
                                                      customerid:
                                                        details[0]
                                                          .fld_customerid,
                                                      orderid: cartID,
                                                      addressid:
                                                        data.fld_address_id,
                                                    },
                                                    "AddShippingChrg"
                                                  ).then((results) =>
                                                    results
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          results.status ===
                                                          200 ||
                                                          results.status === 201
                                                        ) {
                                                          getCartwebsite();
                                                        }
                                                      })
                                                  );
                                                }
                                              }}
                                            />
                                            <div>
                                              {
                                                // eslint-disable-next-line eqeqeq
                                                data.fld_address_type ==
                                                  "Home" ? (
                                                  <span className="cart-address-icons">
                                                    <FaHome />
                                                  </span>
                                                ) : // eslint-disable-next-line eqeqeq
                                                  data.fld_address_type ==
                                                    "Office" ? (
                                                    <span className="cart-address-icons">
                                                      <GiSuitcase />
                                                    </span>
                                                  ) : (
                                                    <span className="cart-address-icons">
                                                      <FaLocationDot />
                                                    </span>
                                                  )
                                              }
                                              <p className="mb-0 fs-6">
                                                {data.fld_address_type}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="p-2">
                                            <p>
                                              <b>{data.fld_contact_name}</b>
                                            </p>
                                            <p>{data.fld_contact_number}</p>
                                            <p>
                                              {data.fld_address_1},{" "}
                                              {data.fld_address_2}, ,
                                              {data.CityName}{" "}
                                              {data.fld_landmark},
                                              {data.StateName} -{" "}
                                              {data.fld_pincode} ,
                                              {data.CountryName}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            ) : (
                              <p className="text-center">No Address Found</p>
                            )}
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <div className="d-flex align-items-center">
                        <span className="cart-accordian-icons">
                          <MdEditLocationAlt />
                        </span>
                        <h4 className="main-heading mb-0">Billing Address</h4>
                      </div>
                    ),
                    children: (
                      <>
                        <div className="row p-2">
                          <div className="col-12">
                            <div className="d-flex add-list-box justify-content-between p-lg-2 my-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h4 className="mb-0">My Address List</h4>
                                  <div className="d-flex">
                                    {addressData && (
                                      <input
                                        type="checkbox"
                                        className="me-2"
                                        checked={check}
                                        onClick={() => {
                                          // eslint-disable-next-line eqeqeq
                                          if (shippingAddress != null) {
                                            // eslint-disable-next-line eqeqeq
                                            if (check == true) {
                                              setCheck(false);
                                              setBillingAddress(
                                                shippingAddress
                                              );
                                            } else {
                                              setCheck(true);
                                              setBillingAddress(
                                                shippingAddress
                                              );
                                            }
                                          } else {
                                            Notiflix.Notify.failure(
                                              "Please select your shipping address"
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                    <p className="mb-0 fs-lg-6">
                                      Same as shipping address
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="read-more new-address">
                                  <a
                                    className="theme-btn border-0 d-inline-block m-0 add-new-address"
                                    onClick={() => {
                                      setShowCanvas(true);
                                    }}>
                                    Add Address
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {addressData.length > 0 && (
                          <div
                            className="row py-2 px-3"
                            style={{
                              display: check == true ? "none" : "flex",
                            }}>
                            {/* Address part */}
                            {addressData.map((data, index) => (
                              <div
                                key={index}
                                className="Col-12"
                                style={{
                                  display:
                                    addressData == null ? "none" : "block",
                                }}>
                                <div className="boxes">
                                  <div className="d-flex">
                                    <div className="p-2 bg-light text-center gap-2 pe-3 d-flex">
                                      <input
                                        type="radio"
                                        checked={
                                          selectedBillingAddress.fld_address_id ===
                                            data.fld_address_id
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setSelectedBillingAddress(data);
                                          setBillingAddress(
                                            data.fld_address_id
                                          );
                                        }}></input>
                                      <div>
                                        {data.fld_address_type == "Home" ? (
                                          <span className="cart-address-icons">
                                            <FaHome />
                                          </span>
                                        ) : data.fld_address_type ==
                                          "Office" ? (
                                          <span className="cart-address-icons">
                                            <GiSuitcase />
                                          </span>
                                        ) : (
                                          <span className="cart-address-icons">
                                            <FaLocationDot />
                                          </span>
                                        )}
                                        <p className="mb-0 fs-6">
                                          {data.fld_address_type}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="p-2">
                                      <p>
                                        <b>{data.fld_contact_name}</b>
                                      </p>
                                      <p>{data.fld_contact_number}</p>
                                      <p>
                                        {data.fld_address_1},{" "}
                                        {data.fld_address_2}, ,{data.CityName}{" "}
                                        {data.fld_landmark},{data.StateName} -{" "}
                                        {data.fld_pincode} ,{data.CountryName}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ),
                  },

                  {
                    key: "5",
                    label: (
                      <div className="d-flex align-items-center">
                        <span className="cart-accordian-icons">
                          <BsCreditCard2BackFill />
                        </span>

                        <h4 className="main-heading mb-0">Payment Method</h4>
                      </div>
                    ),
                    children: (
                      <div className="row m-0 py-3 w-100">
                        <div className="col-lg-6">
                          <div
                            className="mb-3 mb-lg-0"
                            style={{
                              padding: "10px 20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }}>
                            <div className="d-flex align-items-center">
                              <label
                                className="custom-control-label ms-2"
                                for="online-payment">
                                <input
                                  className="checkradio me-2"
                                  type="radio"
                                  name="optradio"
                                  checked={COD == "No" ? true : false}
                                  onChange={() => {
                                    setCOD("No");
                                  }}
                                />
                                Credit Card/ Debit card / Net Banking
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="d-flex justify-content-between flex-wrap align-items-center"
                            style={{
                              padding: "10px 20px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              fontSize: "16px",
                            }}>
                            <label
                              className="custom-control-label"
                              for="cash-on-delivery">
                              <input
                                className="checkradio me-2"
                                type="radio"
                                name="optradio"
                                disabled={
                                  clientData[0]?.paymentType != "single"
                                    ? true
                                    : false
                                }
                                checked={COD == "Yes" ? true : false}
                                onChange={() => {
                                  setCOD("Yes");
                                }}
                              />
                              Cash On Delivery
                            </label>
                            {clientData[0]?.paymentType != "single" && (
                              <div className="text-danger">
                                <small>Not Available</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                defaultActiveKey={["1"]}
                onChange={() => { }}
              />
            </div>
            <div className="col-lg-5 mb-5 pb-lg-5">
              <div
                className="order-summary sticky-top mt-4 mt-lg-0"
                style={{
                  // transition: "1s",
                  transform: stickyTop ? "translateY(125px)" : "",
                }}>
                <div>
                  {cartData.length > 0 ? (
                    <>
                      {cartData.map((product, i) => {
                        if (i === 0) {
                          return (
                            <>
                              <h4 className="main-heading mb-0" key={i}>
                                Order Summary
                              </h4>
                              <table className="order-summary-table order-confirm-table">
                                {offerData.length > 0 && (
                                  <tr>
                                    <td className="border-0 w-100" colSpan={3}>
                                      <div className="d-flex justify-content-between align-items-center w-100 coupon-card-box">
                                        <div
                                          className="d-flex w-100 align-items-center cursor-pointer"
                                          onClick={() => {
                                            if (shippingAddress != null) {
                                              setCouponCard(true);
                                            } else {
                                              Notiflix.Notify.failure(
                                                "Please select the shipping Address"
                                              );
                                            }
                                          }}>
                                          <RiCoupon2Fill />
                                          <p className="ms-2 fw-bold mb-0">
                                            {couponCode == null
                                              ? "Apply Coupon"
                                              : couponCode}

                                            {/* couponCode */}
                                          </p>
                                        </div>
                                        {couponCode == null ? (
                                          <>
                                            {offerData.length > 0 && (
                                              <p>
                                                {offerData.length} Coupons
                                                Available
                                              </p>
                                            )}
                                          </>
                                        ) : (
                                          <p
                                            style={{ cursor: "pointer" }}
                                            className="removeCoupon"
                                            onClick={() => {
                                              removeCoupon(
                                                cartID,
                                                product.fld_coupon_id
                                              );
                                            }}>
                                            {" "}
                                            Remove
                                          </p>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="text-start">
                                    <CiViewList />
                                    Items Total
                                  </td>
                                  <td className="text-end">
                                    {" "}
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"></i>{" "}
                                    {parseFloat(
                                      product.fld_total_net_amount
                                    ).toFixed(0)}{" "}
                                  </td>
                                </tr>
                                {/* {(product.fld_cart_discount_amount ||
                                  product.fld_cart_discount_amount != 0) && (
                                    <tr>
                                      <td className="text-start">
                                        <BiSolidOffer />
                                        Offer Discount{" "}
                                      </td>
                                      <td className="text-end">
                                        {" "}
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"></i>{" "}
                                        {product.fld_cart_discount_amount}{" "}
                                      </td>
                                    </tr>
                                  )} */}
                                {product.fld_coupon_discount != null &&
                                  product.fld_coupon_discount != 0 && (
                                    <tr>
                                      <td className="text-start">
                                        <RiCoupon2Line />
                                        Coupon Discount{" "}
                                      </td>
                                      <td className="text-end">
                                        {" "}
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"></i>{" "}
                                        {product.fld_coupon_discount}{" "}
                                      </td>
                                    </tr>
                                  )}
                                <tr>
                                  <td className="text-start">
                                    <RiEBike2Line />
                                    Delivery Charges{" "}
                                  </td>
                                  <td className="text-end">
                                    {" "}
                                    {product.fld_shipping_charge == null ||
                                      product.fld_shipping_charge == 0 ? (
                                      "Free"
                                    ) : (
                                      <>
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"></i>{" "}
                                        {parseFloat(
                                          product.fld_shipping_charge
                                        ).toFixed(0)}
                                      </>
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    <HiOutlineBuildingLibrary />
                                    GST
                                  </td>
                                  <td className="text-end">
                                    {" "}
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"></i>
                                    {parseFloat(product.fld_total_gst).toFixed(
                                      0
                                    )}
                                  </td>
                                </tr>
                                <tr className="grand-total">
                                  <td
                                    style={{ paddingTop: "0px !important" }}
                                    className="text-start">
                                    <p className="fw-bold">Grand Total</p>
                                  </td>
                                  <td className="text-end fs-6 fw-bold">
                                    {" "}
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"></i>{" "}
                                    {parseFloat(
                                      product.fld_cart_amount
                                    ).toFixed(0)}{" "}
                                    <br />
                                    <i>(Inclusive of all Taxes)</i>
                                  </td>
                                </tr>
                              </table>
                            </>
                          );
                        }
                      })}{" "}
                    </>
                  ) : (
                    <>
                      <h4 className="main-heading mb-0">
                        <Skeleton.Input />
                      </h4>
                      <table className="order-summary-table ">
                        <tr>
                          <td className="border-0 w-100" colSpan={3}>
                            <div className="d-flex justify-content-between align-items-center w-100 coupon-card-box">
                              <div className="d-flex w-100 align-items-center cursor-pointer">
                                <p className="ms-2 fw-bold mb-0">
                                  <Skeleton.Input />
                                </p>
                              </div>
                              <p>
                                <Skeleton.Input />
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <Skeleton.Input />
                          </td>
                          <td className="text-end">
                            {" "}
                            <Skeleton.Input />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton.Input />{" "}
                          </td>
                          <td className="text-end">
                            {" "}
                            <Skeleton.Input />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton.Input />{" "}
                          </td>
                          <td className="text-end">
                            {" "}
                            <Skeleton.Input />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton.Input />{" "}
                          </td>
                          <td className="text-end">
                            {" "}
                            <Skeleton.Input />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton.Input />
                          </td>
                          <td className="text-end">
                            {" "}
                            <Skeleton.Input />
                          </td>
                        </tr>
                        <tr className="grand-total">
                          <td style={{ paddingTop: "0px !important" }}>
                            <p className="fw-bold">
                              <Skeleton.Input />
                            </p>
                          </td>
                          <td className="text-end fs-6 fw-bold">
                            {" "}
                            <Skeleton.Input />
                            <br />
                            <Skeleton.Input />
                          </td>
                        </tr>
                      </table>{" "}
                    </>
                  )}

                  {storeStatus !== "Active" && (
                    <button
                      className={
                        // cartamount < 500
                        //   ? "mt-3 theme-btn-disabled border-0 w-100 add-btn-mobile disabled text-center justify-content-center py-6 fs-6"
                        //   : 
                        "mt-3 theme-btn border-0 w-100 add-btn-mobile text-center justify-content-center py-6 fs-6"
                      }
                      // onClick={() => handleConfirmAddress()}
                      onClick={() => handleConfirmAddress()}
                    // disabled={cartamount < 500}
                    >
                      {" "}
                      Confirm Order
                    </button>
                  )}
                </div>

                {/* {cartamount < 500 && (
                  <div className="order-bottom-card">
                    <>
                      <p className=" mb-0 fw-bold">
                        Add products worth{" "}
                        <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                        {parseFloat(500 - cartamount).toFixed(2)} more
                      </p>
                      <p className=" mb-0">
                        Minimum order amount should be{" "}
                        <i className="fa fa-inr" aria-hidden="true"></i> 500 and
                        above
                      </p>
                    </>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column py-5 my-md-5">
          <div className="empty-icon fs-1">
            <img
              src={NoItem}
              className="img-fluid mb-3"
              alt="No Items are available in your cart"
            />
          </div>
          <p>No Items are available in your cart</p>
          <div className="read-more">
            <a href="/" className=" w-auto d-block">
              Shop Now
            </a>
          </div>
        </div>
      )}
      <Modal
        show={couponCard == true ? true : false}
        // show={couponCard}
        centered
        fullscreen="xl"
        size="md"
        scrollable="true"
        className="coupon-modal"
        onHide={handleToggle}>
        <Modal.Header
          closeButton
          // close={
          // 	<button
          // 		className="coupon-close-btn close "
          // 		onClick={() => {
          // 			setCouponCard(false)
          // 			setCouponErrorCodeTextBox(null)
          // 			setOfferErrorMessage("")
          // 			setEnteredCouponCode(null)

          // 		}}>
          // 		<IoClose />
          // 	</button>
          // }
          className="pb-0 border-0"></Modal.Header>
        <Modal.Body className="py-0">
          <div className="row">
            <div className="col-12 text-center mb-4">
              <RiCoupon2Fill className="main-svg" />
              <div className="section-title mt-0">
                <h4 className="section-title-category-section">
                  Coupons and Offers
                  <div className="title-divider"></div>
                </h4>
              </div>
            </div>
            <div className="col-12 mb-2 apply-coupon-input">
              <input
                type="text"
                placeholder="Enter Coupon Code"
                className="form-control mb-0"
                value={enteredCouponCode}
                onChange={(enteredcouponcode) => {
                  setEnteredCouponCode(enteredcouponcode.target.value);
                }}></input>
              <p className="invalid-coupon-code-textbox">
                {couponErrorCodeTextBox}
              </p>
              <p className="invalid-coupon-code-textbox-color">
                {offerErrorMessageTextBox}
              </p>
            </div>
            <div className="col-12 apply-coupon-button">
              <button
                className="theme-btn border-0 my-3 py-2 w-100 text-center"
                onClick={() => {
                  var login = localStorage.getItem("CustomerData");
                  var logindetails = JSON.parse(login);

                  if (enteredCouponCode != null) {
                    setCouponErrorCodeTextBox(null);
                    setOfferErrorMessageTextBox("");
                    {
                      PostApiCall.postRequest(
                        {
                          whereClause: `where UPPER(fld_code) = UPPER('${enteredCouponCode}') AND cast(getdate() as date) between cast(fld_startdate as date) and cast(fld_enddate as date)`,
                        },
                        "SearchForOfferCode"
                      ).then((results) =>
                        // const objs = JSON.parse(result._bodyText)
                        results.json().then((obj) => {
                          if (results.status == 200 || results.status == 201) {
                            if (obj.data.length != 0) {
                              PostApiCall.postRequest(
                                {
                                  orderid: cartID,
                                  couponid: obj.data[0].fld_offerid,
                                  stateid:
                                    localStorage.getItem("ChangedPincode") ==
                                      null
                                      ? null
                                      : localStorage.getItem("ChangedPincode"),
                                  customerid:
                                    localStorage.getItem("CustomerData") == null
                                      ? null
                                      : logindetails[0].fld_customerid,
                                  status: "apply",
                                },
                                "ApplyOffer"
                              ).then((result) =>
                                result.json().then((obj3) => {
                                  // //console.log(obj3.data);

                                  if (obj3.data) {
                                    if (obj3.data.length != 0) {
                                      Notiflix.Loading.remove();
                                      setOfferErrorCode(obj3.data[0].errorcode);
                                      setOfferErrorMessageTextBox(
                                        obj3.data[0].error
                                      );
                                      setAppliedCoupon(enteredCouponCode);

                                      getCartdetails();
                                    } else {
                                      Notiflix.Loading.remove();
                                    }
                                  } else {
                                    Notiflix.Loading.remove();
                                    Notiflix.Notify.failure(
                                      "Soemthing went wrong. Please try again."
                                    );
                                  }
                                })
                              );
                            } else {
                              setOfferErrorMessageTextBox(
                                "Coupon does not exist"
                              );
                            }
                          }
                        })
                      );
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter Coupon Code");
                  }
                }}>
                Apply Coupon
              </button>
            </div>
          </div>
          <div
            className="rgbox-bottom text-center mt-5 mb-5"
            style={{
              display: offerData.length != 0 ? "block" : "none",
            }}>
            <p>
              <label>OR</label>
            </p>
          </div>
          <div className="coupon-main-box">
            {offerData?.map((data, i) => {
              return (
                <div
                  className={
                    showCouponMoreInformation == data.fld_offerid
                      ? "coupon-card coupon-card-active mb-3 d-flex flex-column align-content-between"
                      : "coupon-card mb-3 d-flex flex-column align-content-between"
                  }
                  key={i}>
                  <div className="px-3 pt-3">
                    <p className="text-dark text-center fw-bold pb-1">
                      {data.fld_name}
                    </p>
                    <hr className="mt-2 border-dark" />
                    <p className="coupon-terms-condition">{data.fld_caption}</p>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <p className="coupon-card-code">{data.fld_code}</p>
                      <span
                        className="view-more cursor-pointer text-dark text-end"
                        style={{
                          display:
                            showCouponMoreInformation == false
                              ? "block"
                              : "none",
                        }}
                        onClick={() => {
                          setShowCouponMoreInformation(data.fld_offerid);
                        }}>
                        View Details <IoIosArrowDown />
                      </span>
                    </div>
                    <p
                      className={
                        appliedCoupon == data.fld_code
                          ? "coupon-terms-condition mb-5"
                          : "d-none"
                      }
                      style={{
                        color: offerErrorCode == "1" ? "#fa4a5b" : "#60b246",
                      }}>
                      {appliedCoupon == data.fld_code ? offerErrorMessage : ""}
                    </p>
                    {/* <p className="coupon-terms-condition">
                            {data.fld_description}
                          </p> */}

                    <div
                      className="coupon-terms-condition ms-2"
                      style={{
                        display:
                          showCouponMoreInformation == data.fld_offerid
                            ? "block"
                            : "none",
                      }}>
                      <hr className="border-dark" />
                      <p className="text-dark">Terms and Conditions</p>
                      <p
                        className={
                          showCouponMoreInformation == data.fld_offerid
                            ? "terms-show"
                            : ""
                        }>
                        {parse(data.fld_termscondition)}
                      </p>
                      <span
                        className="cursor-pointer mt-3 text-dark d-block text-end"
                        onClick={() => {
                          setShowCouponMoreInformation("");
                        }}>
                        View Less <IoIosArrowUp />
                      </span>
                    </div>
                    {couponCode == data.fld_code && (
                      <p
                        className="text-danger"
                        style={{
                          display:
                            offerErrorMessage !=
                              "Coupon applied successfully" ||
                              offerErrorMessage != ""
                              ? "none"
                              : "block",
                        }}>
                        {offerErrorMessage}
                      </p>
                    )}
                  </div>
                  {/* <div className="text-end apply-coupon-btn mt-2"> */}
                  <div className="text-center apply-coupon-btn mt-2">
                    <button
                      className="btn align-items-center place-order text-white w-100"
                      onClick={() => {
                        var login = localStorage.getItem("CustomerData");
                        var logindetails = JSON.parse(login);
                        Notiflix.Loading.circle();
                        couponCode == data.fld_code
                          ? removeCoupon(cartID, data.fld_offerid)
                          : PostApiCall.postRequest(
                            {
                              orderid: cartID,
                              couponid: data.fld_offerid,
                              stateid: localStorage.getItem("ChangedPincode"),
                              customerid:
                                localStorage.getItem("CustomerData") == null
                                  ? null
                                  : logindetails[0].fld_customerid,
                              status: "apply",
                            },
                            "ApplyOffer"
                          ).then((result) =>
                            result.json().then((obj3) => {
                              // //console.log(obj3.data);

                              if (obj3.data) {
                                if (obj3.data.length != 0) {
                                  Notiflix.Loading.remove();
                                  setOfferErrorCode(obj3.data[0].errorcode);
                                  setOfferErrorMessage(obj3.data[0].error);
                                  setAppliedCoupon(data.fld_code);
                                  getCartdetails();
                                } else {
                                  Notiflix.Loading.remove();
                                }
                              } else {
                                Notiflix.Loading.remove();
                                Notiflix.Notify.failure(
                                  "Soemthing went wrong. Please try again."
                                );
                              }
                            })
                          );
                      }}>
                      {/* <span>There are no eligible products for this coupon</span> */}
                      {couponCode == data.fld_code ? (
                        <div className="d-flex justify-content-between align-items-center applied-coupon-btn pb-1 pt-1">
                          <span>
                            Coupon Applied{" "}
                            <IoCheckmarkDone className="font-small" />
                          </span>
                          <span className="cursor-pointer">Remove</span>
                        </div>
                      ) : (
                        "Tap to Apply"
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={showCanvas}
        onHide={handleCloseCanvas}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end">
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">
            Add New Address
          </h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <AddressBookForm toggleCanvas={handleCloseCanvas} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
