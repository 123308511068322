import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import abt from "../img/surabhi.png";
import about from "../img/about-image.png";
import "./aboutpage.css";
import oneastrology from "../img/aboutus-card/All-in-One-Astrology.png"
import detailedhoroscope from "../img/aboutus-card/Detailed-Horoscope-Insights.png"
import personalized from "../img/aboutus-card/Secure-and-Personalized-Guidance.png"

import w1 from "../img/Why-Choose-Us-icons/Comprehensive-Astrological-Services.png";
import w2 from "../img/Why-Choose-Us-icons/High-Quality-Astrological-Forecasts-Advice.png";
import w3 from "../img/Why-Choose-Us-icons/Trustworthy-and-Confidential.png";
import w4 from "../img/Why-Choose-Us-icons/Expertise-and-Experience.png";
import w5 from "../img/Why-Choose-Us-icons/Practical-Solutions.png";
import w6 from "../img/Why-Choose-Us-icons/Holistic-Approach.png";
import hand from "../img/astrology-hand.png"
import year from "../img/about-year.png"
import spin from "../img/about-spin.png"
import { Helmet } from "react-helmet";
import HomeInsta from "../HomeInstaPage/HomeInsta";
import GetSeoData from "../API/GetSeoData";
export default function aboutpage() {
    return (
        <>
            <GetSeoData type="About Us" id={null} />

            <section className="inner-section about-page">
                <div className="page-title-area">

                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>About us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="about-section spacing  mt-lg-0 mt-5">
                    <Container fluid className="pt-lg-5 pb-lg-4">
                        <Row>
                            <Col xs={12} className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column wow fadeInLeft">

                                    <figure className="image-1">
                                        <img title="Dr. Surabhi Bhatnagar" src={about} alt="About Dr. Surabhi Bhatnagar: 20+ years of experience | Blessings Astrology" className="img-fluid" />
                                        {/* </a> */}
                                    </figure>

                                </div>
                            </Col>
                            <Col xs={12} className="content-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="main-heading section-head-2 text-start mb-3">
                                        <h1 className="text-start m-0">About Blessings</h1>
                                        <h3>Get to Know Blessings Astrology!</h3>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general.
                                            With a large user database and a wide range of services, you can find the perfect psychic to help you navigate your life.
                                        </p>
                                        <p>At Blessings Astrology, we have a strong focus on astrology, unlocking your future, and providing accurate predictions. Our accuracy is based on the enriched and vast experience of Dr. Surabhi Bhatnagar, allowing us to provide more accurate predictions than other similar services.
                                        </p>
                                        <p>
                                            Our mission is to provide comprehensive, accurate, and honest insight into all of life's important moments. We help you make better decisions in your life, such as choosing a new career or looking for love. If you want to learn more about yourself (or someone else), or if you want to know what's coming up in your future, then you must use the services of Blessings Astrology.

                                        </p>
                                        <div className="about-year ">
                                            <img src={year} alt="More Than 20+ Year Of Experience | Blessings Astrology" className="img-fluid">

                                            </img>
                                            <h3><span className="year">20+</span> Year Of Experience</h3>



                                        </div>


                                    </div>

                                </div>
                            </Col>
                            <div className="col-lg-12 mt-lg-5 mb-lg-4">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="about-card text-center mb-lg-0 mb-3">
                                            <div className="about-card-image ">
                                                <img src={oneastrology} alt="All-in-One Astrology" className="img-fluid">
                                                </img>
                                            </div>
                                            <h4>All-in-One Astrology






                                            </h4>
                                            <p>We are a complete package for those interested in this amazing science, offering everything from horoscopes to detailed moon phase information.</p>

                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="about-card text-center  mb-lg-0 mb-3">
                                            <div className="about-card-image">
                                                <img src={detailedhoroscope} alt="Detailed Horoscope Insights" className="img-fluid">
                                                </img>
                                            </div>
                                            <h4>Detailed Horoscope Insights












                                            </h4>
                                            <p>Our periodic horoscopes feature detailed descriptions of what will happen in each area of your life based on planetary influences. </p>


                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="about-card text-center  mb-lg-0 mb-3">
                                            <div className="about-card-image">
                                                <img src={personalized} alt="Secure and Personalized Guidance" className="img-fluid">
                                                </img>
                                            </div>
                                            <h4>Secure and Personalized Guidance





                                            </h4>
                                            <p>We provide a safe and private experience for our clients. Services includes love, relationship, career, and business </p>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Row>
                    </Container>
                </div>

                <section className="spacing pb-lg-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 intro-video">
                                <iframe width="900" height="700" src="https://www.youtube.com/embed/yZoG91kb4k0" title="Astrology Uncovered: Fascinating Facts &amp; Your Burning Questions - Part 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                            </div>

                        </div>

                    </div>

                </section>


                <section className="spacing mt-3 mt-lg-0">
                    <div className="container-fluid card-section">
                        <div className="row">
                            <div className="main-heading text-center mb-2 col-12">
                                <h2 className="">Why Choose Us?</h2>
                                <h5>Certified Expertise and Memberships





                                </h5>
                                {/* <p>Unmatched Expertise, Compassionate Guidance, and Personalized Support!</p> */}
                            </div>
                            <div className="col-lg-4">

                                <div className="why-choose-us-card  mb-3 text-lg-end text-center d-flex flex-lg-row-reverse align-items-center">
                                    <div className="card-inner-image mb-3">
                                        <img src={w1} className="img-fluid" alt="Comprehensive Astrological Services" />

                                    </div>
                                    <div className="why-choose-content pe-lg-3">
                                        <h5>
                                            Comprehensive Astrological Services
                                        </h5>
                                        <p>Blessings Astrology offers a wide range of astrology-related services to help you make better decisions in your life.</p>


                                    </div>
                                </div>
                                {/*  */}
                                <div className="why-choose-us-card  mb-3 text-lg-end text-center  d-flex flex-lg-row-reverse align-items-center">

                                    <div className="card-inner-image  mb-3">
                                        <img src={w2} className="img-fluid" alt="Comprehensive Astrological Services" />

                                    </div>


                                    <div className="why-choose-content pe-lg-3">
                                        <h5>
                                            High-Quality Astrological Forecasts, Advice
                                        </h5>
                                        <p>
                                            The platform has a reputation for providing high-quality astrological forecasts and advice, which can help you make informed decisions about your life.

                                        </p>

                                    </div>

                                </div>

                                {/*  */}
                                <div className="why-choose-us-card  mb-3 text-lg-end text-center  d-flex flex-lg-row-reverse align-items-center">

                                    <div className="card-inner-image  mb-3">
                                        <img src={w3} className="img-fluid" alt="Comprehensive Astrological Services" />

                                    </div>


                                    <div className="why-choose-content pe-lg-3">
                                        <h5>
                                            Trustworthy and Confidential
                                        </h5>
                                        <p>
                                            Blessings Astrology is committed to maintaining the highest standards of confidentiality and trust, ensuring that your personal information is kept safe and secure.

                                        </p>
                                    </div>


                                </div>
                            </div>


                            <div className="col-lg-4">
                                <div className="why-choose-image h-100 position-relative ">
                                    {/* <img src={spin} className="img-fluid position-absolute spin">
                                    </img> */}
                                    <img src={hand} alt="Expertise and Experience" className="img-fluid">
                                    </img>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="why-choose-us-card  mb-3 text-lg-start text-center mt-lg-0 mt-3 d-flex align-items-center">

                                    <div className="card-inner-image">
                                        <img src={w4} className="img-fluid" alt="Comprehensive Astrological Services" />

                                    </div>

                                    <div className="why-choose-content ps-lg-3">
                                        <h5>
                                            Expertise and Experience
                                        </h5>

                                        <p>
                                            Dr. Surabhi Bhatnagar's expertise and experience in the field of astrology makes clients comfortable and solutions are given in a practical manner.

                                        </p>
                                    </div>

                                </div>
                                {/*  */}
                                <div className="why-choose-us-card  mb-3 text-lg-start text-center d-flex align-items-center">

                                    <div className="card-inner-image">
                                        <img src={w5} className="img-fluid " alt="Comprehensive Astrological Services" />

                                    </div>


                                    <div className="why-choose-content ps-lg-3">
                                        <h5>
                                            Practical Solutions
                                        </h5>
                                        <p>
                                            Blessings Astrology provides practical solutions that are tailored to your individual needs and challenges, taking into account the demands of modern life.

                                        </p>
                                    </div>


                                </div>
                                {/*  */}
                                <div className="why-choose-us-card  mb-3 text-lg-start text-center d-flex align-items-center">

                                    <div className="card-inner-image mb-3">
                                        <img src={w6} className="img-fluid" alt="Comprehensive Astrological Services" />

                                    </div>


                                    <div className="why-choose-content ps-lg-3">
                                        <h5>
                                            Holistic Approach
                                        </h5>
                                        <p>
                                            Blessings Astrology takes a holistic approach to astrology, considering all aspects of your life to help you achieve balance, harmony, and success in all areas of your life.

                                        </p>

                                    </div>

                                </div>
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </section>


                <HomeInsta />
            </section >
        </>
    );
}