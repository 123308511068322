import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import Col from "react-bootstrap/Col";
import { Autoplay, Pagination } from "swiper";

import "./horoscopepage.css";
import corporate from "../img/corporate-training.png";

import GetApiCall from "../API/GETAPI";
import { Skeleton } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Appointmentform from "../appointmentpage/appointmentform";
import { Modal } from "react-bootstrap";
import PostApiCall from "../API/POSTAPI";
import createDOMPurify from "dompurify";
import GetSeoData from "../API/GetSeoData";
import moment from "moment";
const DOMPurify = createDOMPurify(window);

export default function Horoscopepage() {
  const [horoscopeData, setHoroscopeData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceTitle, setServiceTitle] = useState("");
  useEffect(() => {
    GetHoroscopeData();
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active")
          );
          console.log(obj1.data);
        }
      })
    );
  };

  const GetHoroscopeData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "GetHoroscope"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setHoroscopeData(
            obj1.data.filter((data) => data.fld_status === "Active")
          );
          console.log(obj1.data.filter((data) => data.fld_status === "Active"));
        }
      })
    );
  };
  // for modal
  const [showForm, setShowForm] = useState(false);

  const showModal = (items) => {
    setServiceTitle(items.fld_service_name);
    setShowForm(true);
  };

  const closeModal = () => {
    setShowForm(false);
  };
  return (
    <>
      <GetSeoData type="horoscope prediction" id={null} />

      <section className="inner-section horoscope-page">
        {/* <NavBar /> */}
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="horoscope-section pt-4 py-lg-4 spacing">
          <div className="container-fluid">
            <div className="row">
              <div className="main-heading text-center mb-0 col-12">
                <h1>Weekly Horoscope Prediction</h1>
                {/* <h5>27th Jan - 2nd Feb</h5> */}
                <h5>{moment(horoscopeData[0]?.fld_fromdate).format("Do MMM")} - {moment(horoscopeData[0]?.fld_todate).format("Do MMM")} </h5>
                <p>Check your Moon and Ascendant Signs</p>
              </div>
              {horoscopeData?.length > 0 ? (
                horoscopeData?.map((data, index) => (
                  <div className="col-md-6 py-md-2 px-md-3 mb-3" key={index}>
                    <div className="service-card horoscope-card-image horoscope-page-card">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <img
                            src={data.fld_image}
                            alt={data.fld_name}
                          ></img>
                        </div>
                        <h3>{data.fld_name}</h3>
                        {/* <p className=" ">
                          {data.fld_long_desc.replace(/<\/?p>/g, "")}
                        </p> */}
                        <p
                          className="service-ellipse"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data.fld_long_desc),
                          }}
                        />
                        <div className="text-start w-100 mb-4 lucky">
                          <div className="me-3 d-flex">
                            <strong>Lucky Color :</strong>
                            <div
                              className="color-box mx-1 d-flex align-items-center"
                              style={{
                                background: `${data.fld_colorcode}`,
                              }}
                            ></div>{" "}
                            {data.fld_color}
                          </div>
                          <span className="">
                            <strong>Lucky Number :</strong>{" "}
                            {data.fld_lucky_number}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-left justify-content-left read-more">
                        <a onClick={() => showModal(data)}>
                          Book An Appointment
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="col-md-6 py-md-2 px-md-3 mb-3">
                    <div className="service-card horoscope-card-image horoscope-page-card">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image></Skeleton.Image>
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className=" ">
                          <Skeleton.Input />
                        </p>
                        <div className="text-start w-100 mb-4 lucky">
                          <div className="me-3 d-flex">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </div>
                          <span className="">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-left justify-content-left read-more">
                        <a href="/contact-us">
                          <Skeleton.Button />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-md-2 px-md-3 mb-3">
                    <div className="service-card horoscope-card-image horoscope-page-card">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image></Skeleton.Image>
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className=" ">
                          <Skeleton.Input />
                        </p>
                        <div className="text-start w-100 mb-4 lucky">
                          <div className="me-3 d-flex">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </div>
                          <span className="">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-left justify-content-left read-more">
                        <a href="/contact-us">
                          <Skeleton.Button />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-md-2 px-md-3 mb-3">
                    <div className="service-card horoscope-card-image horoscope-page-card">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image></Skeleton.Image>
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className=" ">
                          <Skeleton.Input />
                        </p>
                        <div className="text-start w-100 mb-4 lucky">
                          <div className="me-3 d-flex">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </div>
                          <span className="">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-left justify-content-left read-more">
                        <a href="/contact-us">
                          <Skeleton.Button />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 py-md-2 px-md-3 mb-3">
                    <div className="service-card horoscope-card-image horoscope-page-card">
                      <div>
                        <div className="icon-wrapper mb-3">
                          <Skeleton.Image></Skeleton.Image>
                        </div>
                        <h3>
                          <Skeleton.Input />
                        </h3>
                        <p className=" ">
                          <Skeleton.Input />
                        </p>
                        <div className="text-start w-100 mb-4 lucky">
                          <div className="me-3 d-flex">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </div>
                          <span className="">
                            <strong>
                              <Skeleton.Button />
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-left justify-content-left read-more">
                        <a href="/contact-us">
                          <Skeleton.Button />
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <Col xs={12} className="main-heading mb-0 mt-3 text-center">
                {/* <h4><span>What Do Our Clients Say</span> About Us?</h4> */}
                <h2>Our Services</h2>
                <p>Nurture Your Spiritual Journey with Our Offerings</p>
              </Col>

              <div className="col-12">
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper testimonial-swiper services-swiper pt-2"
                >
                  {serviceData.length > 0 ? (
                    serviceData.map((items, index) => (
                      <SwiperSlide key={index}>
                        <div className="">
                          <div className="service-card">
                            <div className="icon-wrapper">
                              <img
                                src={items.fld_icon}
                                className="img-fluid"
                                alt={items.fld_service_name}
                              ></img>
                            </div>
                            <h3>{items.fld_service_name}</h3>
                            <p
                              className="service-ellipse"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  items.fld_short_desc
                                ),
                              }}
                            />
                            {/* <p>{items.fld_short_desc.replace(/<\/?p>/g, "")}</p> */}
                            <div className="d-flex gap-3">
                              <div className="read-more">
                                <Link
                                  to={`/service/${items?.fld_route}`}
                                >
                                  {" "}
                                  Read More
                                </Link>
                              </div>
                              <div className="book-now">
                                <a onClick={() => showModal(items)}>Book Now</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <>
                      <SwiperSlide>
                        <div className="">
                          <div className="service-card">
                            <div className="icon-wrapper">
                              <Skeleton.Image></Skeleton.Image>
                            </div>
                            <h3>
                              <Skeleton.Input />
                            </h3>
                            <p>
                              <Skeleton.Input />
                            </p>
                            <div className="d-flex gap-3">
                              <div className="read-more">
                                <Skeleton.Button />
                              </div>
                              <div className="book-now">
                                <a href="/contact-us">
                                  <Skeleton.Button />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="">
                          <div className="service-card">
                            <div className="icon-wrapper">
                              <Skeleton.Image></Skeleton.Image>
                            </div>
                            <h3>
                              <Skeleton.Input />
                            </h3>
                            <p>
                              <Skeleton.Input />
                            </p>
                            <div className="d-flex gap-3">
                              <div className="read-more">
                                <Skeleton.Button />
                              </div>
                              <div className="book-now">
                                <a href="/contact-us">
                                  <Skeleton.Button />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="">
                          <div className="service-card">
                            <div className="icon-wrapper">
                              <Skeleton.Image></Skeleton.Image>
                            </div>
                            <h3>
                              <Skeleton.Input />
                            </h3>
                            <p>
                              <Skeleton.Input />
                            </p>
                            <div className="d-flex gap-3">
                              <div className="read-more">
                                <Skeleton.Button />
                              </div>
                              <div className="book-now">
                                <a href="/contact-us">
                                  <Skeleton.Button />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="">
                          <div className="service-card">
                            <div className="icon-wrapper">
                              <Skeleton.Image></Skeleton.Image>
                            </div>
                            <h3>
                              <Skeleton.Input />
                            </h3>
                            <p>
                              <Skeleton.Input />
                            </p>
                            <div className="d-flex gap-3">
                              <div className="read-more">
                                <Skeleton.Button />
                              </div>
                              <div className="book-now">
                                <a href="/contact-us">
                                  <Skeleton.Button />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="">
                          <div className="service-card shop-online">
                            <div className="icon-wrapper">
                              <img src={corporate} alt="Corporate Training" />
                            </div>

                            <h3>Corporate Training</h3>
                            <p>
                              Blessings Astrology offers unique and
                              comprehensive corporate training sessions designed
                              to enhance employee well-being...
                            </p>
                            <div className="d-flex gap-3">
                              <div className="read-more ">
                                <a href="/corporate-training-service">
                                  {" "}
                                  Read More
                                </a>
                              </div>
                              <div className="book-now ">
                                <a
                                  onClick={() =>
                                    showModal("Corporate Training")
                                  }
                                >
                                  Book Now
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </section>
      {showForm && (
        <Appointmentform
          serviceName={serviceTitle}
          showForm={showForm}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
