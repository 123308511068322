import React from "react";
import PhotoGallery from "./photogallery";
import ImageGallery from "./imagegallery";
import { Helmet } from "react-helmet";
import GetSeoData from "../API/GetSeoData";
export default function gallerypage() {
  return (
    <>
      <GetSeoData type="journey of excellence" id={null} />
      <section className="inner-section gallery-page">
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="spacing">
        <div className="container-fluid py-5">
          <div className="row">
            <div className="main-heading col-12 t-head mb-4 text-center">
              <h1>Journey of Excellence</h1>
              <p className="text-center">
                Milestones and Successes: A Journey of Excellence
              </p>
            </div>
            <div className="col-12">
              {/* <PhotoGallery /> */}
              <ImageGallery />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
