import React from "react";
import "./HomeInsta.css";
import { Skeleton } from "antd";
import Reel from "../img/reel.mp4";
import Reel1 from "../img/BLESSINGS-1.mp4";
import Reel2 from "../img/BLESSINGS-2.mp4";
import Reel3 from "../img/BLESSINGS-3.mp4";
import Reel4 from "../img/BLESSINGS-4.mp4";
import reel5 from "../img/blessings-5.mp4"
import reel6 from "../img/blessings-6.mp4"
import reel7 from "../img/blessings-7.mp4"

import { FaInstagram } from "react-icons/fa";

export default function HomeInsta() {
  const instagramData = [
    {
      url: reel5,
      //   gif: video5,
      alt: "Blessings",
      link: "https://www.instagram.com/reel/DECnBU1ySlG/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    },
    {
      url: reel6,
      //   gif: video5,
      alt: "Blessings",
      link: "https://www.instagram.com/reel/DEZi43CJx3G/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    },
    {
      url: reel7,
      //   gif: video5,
      alt: "Blessings",
      link: "https://www.instagram.com/reel/DCwam7TPeNz/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    },

    {
      url: Reel,
      //   gif: video1,
      alt: "Blessings",
      link: "https://www.instagram.com/reel/C_iPUdvtim1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
    {
      url: Reel1,
      //   gif: video2,
      alt: "Blessings",
      link: "https://www.instagram.com/reel/C-ucrn9SIw1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },

  ];
  return (
    <>
      <section className=" spacing mb-3 mt-4">
        <div className="container-fluid ">
          <div className="row">
            <div className="text-center col-12 text-center t-head mb-lg-4 main-heading">
              <h2 className="mb-0">Let's Socialize </h2>
              <p>Join Our Cosmic Community on Instagram Today!</p>
            </div>
            {instagramData.length > 0 ? (
              <div className="col-12">
                <div className="video-box">
                  {instagramData.map((item, index) => (
                    <div className="video-card position-relative" key={index}>
                      <a href={item.link} target="blank">
                        <video src={item.url} muted loop autoPlay playsInline />
                      </a>
                      <div className="insta-icon">
                        <a href={item.link} target="blank">
                          <FaInstagram />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <div className="col-12">
                  <div className="video-box">
                    <div className="video-card">
                      <Skeleton.Image />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="video-box">
                    <div className="video-card">
                      <Skeleton.Image />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="video-box">
                    <div className="video-card">
                      <Skeleton.Image />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="video-box">
                    <div className="video-card">
                      <Skeleton.Image />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="video-box">
                    <div className="video-card">
                      <Skeleton.Image />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
