import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import "./blog.css";
import GetApiCall from "../API/GETAPI";
import parse from "html-react-parser";
import GetSeoData from "../API/GetSeoData";
const Blogpage = () => {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetBlogArticle();
  }, []);
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data);
      });
    });
  };

  return (
    <>
      <GetSeoData type="Blogs" id={null} />

      <section className="inner-section blog-page">
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Horoscope Prediction</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="blogsection spacing">
        <div className="container-fluid">
          <div className="row">
            <div className="main-heading mb-0 col-12 mt-4 text-center">
              <h1 className="pb-2">
                Zodiac Blogs
              </h1>
              <p className="text-dark">
                Astrology is the divine knowledge and pseudoscience which
                involves nine planets, twenty seven constellations, longitudes
                and latitudes and celestial degrees to predict the events and
                changes in one's life.{" "}
              </p>
              <p className="text-dark">
                The practice probably began as soon as humans first started to
                observe astronomical cycles. Astrology explains that everything
                that you do in life is influenced by the positions of the stars
                and Planets at the moment of your birth. It asserts that you are
                born according to karmic justice and judgment and that karma is
                written out into the stars at that exact moment. The alignment
                of stars and planets at the time of your birth is based on the
                karma you have earned in your previous births and hence results
                are awarded to you accordingly.
              </p>
              <p className="text-dark">
                When we study Astrology one of the basis we work on is Zodiac.
                Zodiac is the imaginary celestial sphere around the earth which
                is divided into 12 divisions . These divisions are known as
                Rashis or signs. These are: ARIES, TAURUS, GEMINI, CANCER, LEO,
                VIRGO,LIBRA, SCORPIO, SAGITTARIUS, CAPRICORN, AQUARIUS, PISCES.
              </p>
            </div>
            {blogData.map((data, index) => (
              <div className="col-md-6 col-lg-4 py-md-2 px-md-3 mb-3" key={index}>
                <div className="service-card blogs-card">
                  <div className="icon-wrapper  blog-page-image ">
                    <Link
                      to={`/blog/${data.fld_route}`}
                    > <img src={data.fld_blogimage}
                      className=" img-fluid aries-image"
                      alt="Astrology Services"
                    ></img>
                    </Link>
                  </div>
                  <div className="blog-page-content">
                    <h4>
                      <Link
                        to={`/blog/${data.fld_route}`}
                      >{data.fld_title}</Link>{" "}
                    </h4>
                    <p className="m-0 ">{parse(data.fld_short_description)}
                    </p>

                    <div className="d-flex align-items-left justify-content-left read-more">
                      <Link
                        to={`/blog/${data.fld_route}`}

                      >Read More</Link>
                    </div>
                  </div>
                </div>
              </div>))}

          </div>
        </div>
      </section>
    </>
  );
};

export default Blogpage;
