import { useEffect, useState } from "react";
import "./recentblogsection.css";
import GetApiCall from "../API/GETAPI";
import parse from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "antd";
export default function RecentBlogSection() {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    GetBlogArticle();
  }, []);
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        const latestBlog = obj.data.sort(
          (a, b) => a.fld_createdon - b.fld_createdon
        );
        setBlogData(latestBlog);
      });
    });
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 200);
      });
    }
  }, []);
  return (
    <div
      className={
        scroll ? "active sticky-top recent-blogs" : "sticky-top recent-blogs"
      }>
      <h2 class="main-heading">Recent Blogs</h2>

      <div className="blog-btn-card px-0">
        {blogData.length > 0 ? (
          <ul>
            {blogData.slice(0, 4).map((data, index) => (
              <li className="d-flex mb-3 blog-side-card" key={index}>
                <div className="blog-side-image recent-image">
                  <Link
                    to={`/blog/${data.fld_route}`}
                  >
                    <img
                      src={data.fld_blogimage}
                      className="img-fluid"
                      alt={data.fld_title}
                    />
                  </Link>
                </div>
                <div className="blog-side-content blog-svg px-2">

                  <div className="anchor-para px-1">
                    <Link
                      to={`/blog/${data.fld_route}`}
                    >
                      <h4>{data.fld_title}</h4>
                    </Link>
                    <p>{parse(data.fld_short_description)}</p>
                  </div>
                </div>
              </li>
            ))}
            <div className="text-center mt-4">
              <a href="/blogs" className="blog-view-all">View All</a>
            </div>
          </ul>
        ) : (
          <ul>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
            <li className="d-flex mb-3 blog-side-card">
              <div className="blog-side-image recent-image">
                <Skeleton.Image className="img-fluid" alt="" />
              </div>
              <div className="blog-side-content blog-svg px-2">
                <div>
                  <span className=" p-1 my-3">
                    <Skeleton.Input />
                  </span>
                </div>
                <div className="anchor-para px-1">
                  <h4>
                    <Skeleton.Input />
                  </h4>
                  <p>
                    <Skeleton.Input />
                  </p>
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
