import React, { useEffect, useState } from "react";
import "./servicepage.css";
import { Helmet } from "react-helmet";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Appointmentform from "../appointmentpage/appointmentform";
import GetApiCall from "../API/GETAPI";
import Corporatetraining from "../servicepage/serviceinner/corporatetraining";
import createDOMPurify from "dompurify";
import GetSeoData from "../API/GetSeoData";
import { useParams } from "react-router-dom";
import PostApiCall from "../API/POSTAPI";
import { Skeleton } from "antd";
const DOMPurify = createDOMPurify(window);
export default function ServiceDetails() {
  const params = useParams()
  const [showForm, setShowForm] = useState(false);
  const [serviceTitle, setServiceTitle] = useState("");
  const [faqData, setFaqData] = useState([]);
  const [serviceDetail, setServiceDetail] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // GetFAQData();
  }, [params.servicename]);
  useEffect(() => {
    GetServiceData();
  }, [params.servicename]);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: `where fld_route = '${params.servicename}'`,
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          const fetchedservice =
            obj1.data.filter((data) => data.fld_status === "Active");
          setServiceDetail(fetchedservice
          );
          setLoading(true)
          GetApiCall.getRequest("GetJsonFAQS").then((results) => {
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                const serviceFaq = obj.data.filter(
                  (item) => item.service === fetchedservice[0]?.fld_service_name
                );
                setFaqData(serviceFaq);
              }
            });
          });

        }
      })
    );
  };



  const showModal = (details) => {
    setShowForm(true);
    setServiceTitle(details?.fld_service_name);
  };

  const closeModal = () => {
    setShowForm(false);
  };
  const textContent = new DOMParser().parseFromString(serviceDetail[0]?.fld_long_desc, 'text/html').body.textContent;

  return (
    <>
      <GetSeoData type="service" id={null} route={params.servicename} />

      <section className="inner-section service-page">
        {/* <NavBar /> */}
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
                {loading == true ? <li>{serviceDetail[0]?.fld_service_name}</li> : <Skeleton.Input />}
              </ul>
            </div>
          </div>
        </div>

        <section className="spacing ">
          <div className="container-fluid pt-4">
            {loading === true ? <><div className="main-heading mb-0 col-12 mb-4 text-center">
              <h1>{serviceDetail[0]?.fld_service_name}</h1>
              {/* <p className="m-0">lorem lorem lorem lorem lorem</p> */}
            </div>
              <div className="row astrology-page-service p-lg-4">
                <div className="col-lg-4">
                  <div className="astrology-image">
                    <img
                      src={serviceDetail[0]?.fld_image}
                      className="img-fluid w-100  "
                      alt={`${serviceDetail[0]?.fld_service_name} By Dr. Surabhi Bhatnagar | Blessings Astrology`}
                    />
                  </div>
                </div>
                <div className="col-lg-8 service-inner-card px-lg-3 d-lg-flex align-items-center">
                  <div className="astrology-content mt-lg-0 mt-3">
                    <p>{textContent}</p>
                    <div className="read-more mt-4">
                      <a onClick={() => showModal(serviceDetail[0])}>
                        {" "}
                        Book An Appointment
                      </a>
                    </div>
                  </div>
                </div>
              </div></> :
              <><div className="main-heading mb-0 col-12 mb-4 text-center">
                <Skeleton.Input />
                {/* <p className="m-0">lorem lorem lorem lorem lorem</p> */}
              </div>
                <div className="row astrology-page-service p-lg-4 astro-skelton">
                  <div className="col-lg-4">
                    <div className="astrology-image">
                      <Skeleton.Image
                        className="img-fluid w-100  "
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 service-inner-card px-lg-3">
                    <div className="astrology-content mt-lg-0 mt-3">
                      <Skeleton.Input />
                      <div className="read-more mt-4">
                        <Skeleton.Input className="w-100" />
                      </div>
                    </div>
                  </div>
                </div></>
            }

            {faqData.length > 0 && (
              <div className="row">
                <div className="main-heading text-center mb-0 col-12 ">
                  <h2 className=" my-3">FAQs</h2>
                  <h5>
                    Wondering About {serviceDetail[0]?.fld_service_name}? Find Your
                    Answers Here
                  </h5>
                </div>
                <div className="col-12 faq-main">
                  <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    <Tab eventKey={1} title={serviceDetail[0]?.fld_service_name}>
                      <Accordion defaultActiveKey="0">
                        {faqData.map((items, index) => (
                          <>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {items.fld_question}
                              </Accordion.Header>
                              <Accordion.Body>
                                {items.fld_answer}
                              </Accordion.Body>
                            </Accordion.Item>
                          </>
                        ))}
                      </Accordion>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
      {serviceDetail[0]?.fld_service_name === "Corporate Training" && (
        <Corporatetraining />
      )}
      {showForm && (
        <Appointmentform
          serviceName={serviceDetail[0]?.fld_service_name}
          showForm={showForm}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
