import { Link } from "react-router-dom";
import Appointmentform from "../appointmentpage/appointmentform";
import { Input, Skeleton } from "antd";
import PostApiCall from "../API/POSTAPI";
import "./RequestCall.css"
import createDOMPurify from "dompurify";
import GetSeoData from "../API/GetSeoData";
import { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const DOMPurify = createDOMPurify(window);
const RequestCall = () => {
    const [serviceData, setServiceData] = useState([]);
    // const [serviceTitle, setServiceTitle] = useState("");s
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [selectedService, setSelectedService] = useState("")
    const [dialcode, setDialcode] = useState("");

    const history = useHistory()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        GetServiceData();
    }, []);

    const GetServiceData = () => {
        PostApiCall.postRequest(
            {
                catCount: "*",
                whereClause: "",
            },
            "Services"
        ).then((results) =>
            results.json().then((obj1) => {
                if (results.status === 200 || results.status === 201) {
                    setServiceData(
                        obj1.data.filter((data) => data.fld_status === "Active").sort((a, b) => a.fld_id - b.fld_id)
                    );
                }
            })
        );
    };

    const handleRegexValidation = (email) => {
        let result;
        if (email) {
            result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        }
        return result;
    };


    // dialcode phone number
    const handleCountryChange = (value, country) => {
        setMobile(country.inputValue)
        setDialcode("+" + country.country.dialCode)
    };



    // emailer html for client 
    const htmlForClient =
        "<!doctype html>" +
        "<html>" +
        "<head>" +
        '<meta charset="utf-8">' +
        "<title>Enquiry</title>" +
        "</head>" +
        "<body style=font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif>" +
        '<div class="wrapper" style="padding: 10px;width: 1000px;margin: auto;border: .5px solid;">' +
        '<div class="container" style="padding: 5px">' +
        '<img style="width: 100px; background-color:white;" src="https://store.bechofy.in/images/dbo/ProductImages/700647-newlogofooter.webp" alt="" >' +
        " </div><hr>" +
        '<div class="container">' +
        " <h4>Dear Sir/Mam,</h4>" +
        " <p>You have recieved a call back request enquiry from the " +
        "Blessings Astrology Website" +
        " Below are the details submitted by the user. </p>" +
        "</div>" +
        "<br>" +
        '<table style="padding-bottom: 20px">' +
        "<tr>" +
        "<td>Name:</td>" +
        '<td style="padding-left: 110px">' +
        name +
        "</td>" +
        " </tr>" +
        " <tr>" +
        "<td>Email:</td>" +
        '<td style="padding-left: 110px">' +
        email +
        " </td>" +
        " </tr>" +
        " <tr>" +
        "<td>Mobile No.:</td>" +
        '<td style="padding-left: 110px">' +
        mobile +
        " </td>" +
        "</tr>" +
        "<td>Service Name:</td>" +
        '<td style="padding-left: 110px">' +
        selectedService +
        " </td>" +
        "</tr>" +
        " <tr>" +
        "</table>" +
        "</div>" +
        "</body>" +
        "</html>"



    // emailer html for user 
    const htmlForUser =
        "<!doctype html>" +
        "<html>" +
        "<head>" +
        '<meta charset="UTF-8">' +
        '<meta name="viewport" content="width=device-width, initial-scale=1.0">' +
        "<title>Message Notification</title>" +
        "</head>" +
        '<body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; margin: 0; padding: 0; background-color: #f9f9f9;">' +
        '<table style="margin: 0px auto; background-color: #fff;  border-collapse: collapse;width:100%;">' +
        "<tr>" +
        '<td style="background-color: #410f58; color: #fff; padding: 15px; text-align: center; font-size: 18px;">' +
        "<strong>Thank You for Booking</strong>" +
        "</td>" +
        " </tr>" +
        "<tr>" +
        '<td style="padding: 20px 20px 0 20px; ">' +
        '<div style="text-align: center">' +
        '<img  style="background-color: white;" src="https://store.bechofy.in/images/dbo/ProductImages/700647-newlogofooter.webp" height="70px">' +
        "</div>" +
        '<p style="margin: 0;">Dear Sir/Mam,</p>' +
        '<p style="margin-top: 10px;">Your consultation request with Dr. Surabhi Bhatnagar has been received. Our team will be in touch shortly to confirm your appointment timings. Below are the details of your request:</p>' +
        '<p>We will call you shortly to confirm your session timing. If you have any updates or questions, feel free to calls us at +91 - 98211 55251' +
        '</p > ' +
        '<p> We look forward to assisting you' +
        '</p >' +
        '<div style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; ">' +
        '<div style="border-spacing: 0; background-color: #ffffff;">' +
        "<tr>" +
        '  <table style="border-spacing: 0; background-color: #ffffff; overflow: hidden;text-align: center;width: 100%;">' +
        "<tr>" +
        '<td style="padding: 10px; font-size: 24px; color: #410f58; font-weight: bold;"> Blessings Astrology</td>' +
        "</tr>" +
        "<tr>" +
        '<td style="padding: 0px;">' +
        '<p style="margin: 5px 0;">' +
        '<img src="https://tlr.stripocdn.email/content/guids/CABINET_09e9fe3469e9e38cee45638bc890f8fb7fa30bea0ae9e8d1c37288fc5f1f0d62/images/envelope_8.png" alt="Email Icon" style="height: 20px;">' +
        "surabhibhatnagar@blessingsastrology.com" +
        "</p>" +
        '<a href="tel:98211 55251" style="margin: 5px 0;">' +
        '<img src="https://tlr.stripocdn.email/content/guids/CABINET_09e9fe3469e9e38cee45638bc890f8fb7fa30bea0ae9e8d1c37288fc5f1f0d62/images/mobilebutton_1.png" alt="Phone Icon" style="height: 20px;">' +
        "+91 - 98211 55251" +
        "</a>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        '<td style="padding: 10px;">' +
        '<a href="https://www.facebook.com/blessings.astrologysolutions?_rdr" style="margin: 0 5px;">' +
        '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Facebook.webp" alt="Facebook" style="height: 30px;">' +
        "</a>" +
        '<a href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag" style="margin: 0 5px;">' +
        '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Instagram.webp" alt="Instagram" style="height: 30px;">' +
        "</a>" +
        '<a href="https://www.youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ" style="margin: 0 5px;">' +
        '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Youtube.webp" alt="YouTube" style="height: 30px;">' +
        "</a>" +
        '<a href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/" style="margin: 0 5px;">' +
        '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Linkedin.webp" alt="LinkedIn" style="height: 30px;">' +
        "</a>" +
        "</td>" +
        "</tr>" +
        "<tr>" +
        '<td style="padding: 20px; background-color: #410f58; color: #ffffff; font-size: 14px;">Emaar Palm Gardens, Tower 9 Unit-002, Sector-83, Gurugram - 122 004, Haryana.</td>' +
        "</tr>" +
        "</table>" +
        "</tr>" +
        "</div>" +
        "</tr>" +
        "</table>" +
        "</div>" +
        "</td>" +
        "</tr>" +
        "</table>" +
        "</body>" +
        "</html>"

    const submitForm = () => {
        if (name === "") {
            return Notiflix.Notify.failure("Please enter your name")
        }
        if (mobile === "" || mobile.replace(/\s+/g, '') === dialcode.replace(/\s+/g, '')) {
            return Notiflix.Notify.failure("Please enter your phone number")
        }
        if (email === "") {
            return Notiflix.Notify.failure("Please enter your email")
        }
        if (!handleRegexValidation(email)) {
            return Notiflix.Notify.failure("Please enter a valid email")
        }
        if (selectedService === "") {
            return Notiflix.Notify.failure("Please select a service")
        }
        bookAppointment()
    }
    const bookAppointment = () => {
        Notiflix.Loading.dots("")
        PostApiCall.postRequest(
            {
                appointment_id: null,
                name: name,
                gender: "",
                maritalStatus: "",
                tob: null,
                dob: null,
                birthPlace: "",
                mobile: mobile,
                email: email,
                area: null,
                time: "",
                slotduration: "",
                date: "",
                service: selectedService,
                image1: null,
                image2: null,
                message: "",
                status: "New",
            },
            "BookAppointment"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    sendMailer()

                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
                }
            })
        );

    }

    const sendMailer = () => {
        PostApiCall.postRequest(
            {
                email: email,
                mailerHtmlUser: htmlForUser,
                mailerHtmlClient: htmlForClient,
                clientMailerSubject: "You Have a New Appointment Request",
                userMailerSubject: "Your Appointment Request has been Received!"
            },
            "BookAppointmentMailer"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status === 200 || results.status === 201) {
                    setName("")
                    setMobile("")
                    setDialcode("")
                    setEmail("")
                    setSelectedService("")
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("Form Submitted Successfully.", {
                        zindex: 99999,
                    });
                    // history.push("/thankyou-for-booking");
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
                }
            })
        );
    }
    return (
        <>
            <GetSeoData id={null} type="requestcall" />
            <section className="requestcall ">
                {/* <NavBar /> */}
                <div className="page-title-area request-page-banner">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-center">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="main-heading text-center mb-0 col-12">
                            <h1 className="pb-2 py-2">Request a Call Back</h1>
                            <h5>Find Answers, Make the Right Decisions





                            </h5>
                            <p>Thousands have found clarity, peace, and the right path with Dr. Surabhi Bhatnagar's expert guidance in Astrology, Vastu, Horoscope Readings, Meditation, and Healing Remedies. From life decisions to creating a positive home environment, personalized consultations have helped many bring balance and happiness into their lives.
                            </p>
                            <p>Trusted by Many | Right Guidance, Better Decisions | Positive Changes That Last</p>

                        </div>
                        <div className="col-lg-12">
                            <div className="my-5 p-lg-5 p-3 rounded w-100 form-card mb-0">
                                <div className="row">
                                    <div className="col-lg-4 mb-4">
                                        <label for="name" className="mb-1">Name <span className="text-danger">*</span></label>
                                        <Input onChange={(e) => setName(e.target.value)} id="name" className="ant-input css-1hpnbz2 ant-input-outlined" type="text" value={name} />
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <label for="name" className="mb-1">Mobile Number <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            id="phone"
                                            defaultCountry="in"
                                            value={mobile}
                                            // disabled={selectedService !== "" ? false : true}
                                            forceDialCode={true}
                                            onChange={handleCountryChange}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <label for="email" className="mb-1">Email Address <span className="text-danger">*</span></label>
                                        <input onChange={(e) => setEmail(e.target.value)} id="email" className="ant-input css-1hpnbz2 ant-input-outlined" type="text" value={email} />
                                    </div>
                                    <div className="col-12 mb-">

                                        <label for="name" className="mb-1">Services <span className="text-danger">*</span></label>
                                        <select aria-label="Default select example" className="form-select" id="service" onChange={(e) => {
                                            setSelectedService(e.target.value)
                                        }}>
                                            <option value disabled selected>
                                                Select Service
                                            </option>
                                            {serviceData.map((items, index) => (
                                                <option value={items.fld_service_name} key={index}>
                                                    {items.fld_service_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="request-button mt-4" onClick={submitForm}>
                                        <button type="submit">Request a call back</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 my-3">
                            <div className="row">
                                <div className="main-heading text-center mb-0 col-12">
                                    <h2 className="pb-2 py-2">Our Services</h2>

                                </div>
                                {serviceData?.length > 0 ? (
                                    serviceData?.map((items, index) =>
                                    (
                                        <div
                                            className="col-md-4 py-md-2 px-md-3 mb-3"
                                            key={index}
                                        >
                                            <div className="service-card  position-relative request-card card-service">

                                                <div className="icon-wrapper mb-2">
                                                    <img
                                                        src={items.fld_icon}
                                                        className="img-fluid"
                                                        alt={items.fld_service_name}
                                                    />
                                                </div>
                                                <div>
                                                    <h3>{items.fld_service_name}</h3>
                                                    {/* <p>{items.fld_short_desc}</p> */}

                                                    <p
                                                        className="service-ellipse"
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(items.fld_short_desc),
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <div className="col-md-6 col-lg-4 py-md-2 px-md-3 mb-3">
                                            <div className="service-card  position-relative  card-service">
                                                <div>
                                                    <div className="icon-wrapper mb-3">
                                                        <Skeleton.Image className="img-fluid" />
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p className="service-ellipse">
                                                        <Skeleton.Input />
                                                    </p>
                                                </div>
                                                <div className="d-flex gap-3 button-read">
                                                    <div className="read-more">
                                                        <Skeleton.Button />
                                                    </div>

                                                    <div className="book-now">
                                                        <a>
                                                            <Skeleton.Button />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 py-md-2 px-md-3 mb-3">
                                            <div className="service-card  position-relative  card-service">
                                                <div>
                                                    <div className="icon-wrapper mb-3">
                                                        <Skeleton.Image className="img-fluid" />
                                                    </div>
                                                    <h3>
                                                        <Skeleton.Input />
                                                    </h3>
                                                    <p className="service-ellipse">
                                                        <Skeleton.Input />
                                                    </p>
                                                </div>
                                                <div className="d-flex gap-3 button-read">
                                                    <div className="read-more">
                                                        <Skeleton.Button />
                                                    </div>

                                                    <div className="book-now">
                                                        <a>
                                                            <Skeleton.Button />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>


                    </div>

                </div>
            </section >
        </>
    )

}

export default RequestCall;