import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./whychoose.css";
import wcImg from "../../img/wcu/why-choose-surbhimam-image.png";
// import w1 from "../../img/Why-Choose-Us-icons/Comprehensive-Astrological-Services.png";
import w2 from "../../img/Why-Choose-Us-icons/High-Quality-Astrological-Forecasts-Advice.png";
import w3 from "../../img/Why-Choose-Us-icons/Trustworthy-and-Confidential.png";
import w4 from "../../img/Why-Choose-Us-icons/Expertise-and-Experience.png";
import w5 from "../../img/Why-Choose-Us-icons/Practical-Solutions.png";
import w6 from "../../img/Why-Choose-Us-icons/Holistic-Approach.png";
import image from "../../img/Why-Choose-Us-icons/IAF-logo.jpg"
import experience from "../../img/Why-Choose-Us-icons/20+experience.png"


function WhyChoose() {
  return (
    <>

      <section className="card-section spacing pb-lg-4 pt-2 mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className=" main-heading  col-12  t-head mb-lg-4">
                <p>Dr. Surabhi Bhatnagar has positively transformed the lives of over 10,000 people across India and countries like the USA, Australia, Germany, the UK, Canada, Singapore, and more. With over 20 years of experience, she has received several prestigious awards and accolades in the field of occult sciences.
                  As an author, Dr. Bhatnagar has written a book on Vastu, sharing her expertise to help people create harmony and balance in their lives and spaces. She provides simple, practical, and effective solutions after thoroughly understanding each individual's challenges, leading them toward happiness, prosperity, and success.
                  Her expertise spans multiple domains, including Vastu, Astrology, Numerology, Tarot Card Reading, Reiki, Handwriting & Signature Analysis, and other healing practices. She is also a lifetime member of the International Astrology Foundation (USA) and is associated with the International Center for Reiki Training (ICRT), USA. Dr. Surabhi Bhatnagar's personalized approach continues to guide and inspire people worldwide.</p>
              </div>

            </div>
            <div className="col-lg-4 t-card-img">
              <img src={wcImg} className="img-fluid w-100 " alt="Achivements of Dr. Surabhi Bhatnagar"></img>



            </div>
            <div className="col-lg-8 main-card-section d-lg-block d-none">
              <div className="row h-100">
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-1">
                    <div className="ts-icon">
                      <img src={image} className="img-fluid w-50" alt="LifeTime Member of IAF | Blessings Astrology" />
                      {/* <h3>
                        Comprehensive Astrological Services
                      </h3> */}
                      <h3>LifeTime Member of IAF
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-2">
                    <div className="ts-icon">
                      <img src={w2} className="img-fluid w-50" alt=" Trusted Expert" />
                      <h3>
                        Trusted Expert
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="three-section-card three-section-card-3">
                    <div className="ts-icon">
                      <img src={w5} className="img-fluid w-50" alt="Accurate and Reliable Predictions" />

                      <h3>
                        Accurate and Reliable Predictions
                      </h3>
                    </div>
                    {/* <div className="ts-content">

                      <p>
                        Joining hands with NAB Delhi can make a difference in the lives of visually impaired individuals. As a CSR partner, your organization can help provide vital resources and services to those in need. By supporting us, your organization can contribute to the empowerment and inclusion of the visually impaired community. Join us today to create a positive impact and fulfill your CSR objectives.
                      </p>
                      <a href="/be-a-csr" alt="NAB Delhi Be a CSR" aria-label="NAB Delhi Be a CSR Page" className="default-btn">Be a CSR Partner</a>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card">
                    <img src={w4} className="img-fluid w-50" alt="Personal Empowerment" />
                    <h3>
                      Personal Empowerment





                    </h3>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card three-section-card-4 three-section-card-5">
                    <img src={experience} className="img-fluid w-50" alt="More Than 20+ Year of Experience" />
                    <h3>
                      More Than <br></br> 20+ Year of Experience                   </h3>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="ts-icon three-section-card three-section-card-4">
                    <img src={w6} className="img-fluid w-50" alt="Holistic Approach" />
                    <h3>
                      Holistic Approach
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhyChoose;